// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import H1 from 'components/h1'
import { tablet, mobile } from 'shared/media-queries'

export const DefaultHeading = ({ children }: { children: string }) => (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '44px' },
		}}
	>
		{children}
	</H1>
)

export const DefaultSubHeading = ({ children }: { children: string }) => (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[tablet]: { fontSize: '20px', maxWidth: '375px', margin: '0 auto' },
		}}
	>
		{children}
	</Div>
)
