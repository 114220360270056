// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import SectionSpacer from 'views/components/section-spacer'
import { gray20 } from 'shared/colors'

const SectionBreak = () => (
	<Div>
		<SectionSpacer />
		<Div borderTop={`1px solid ${gray20}`} />
		<SectionSpacer />
	</Div>
)

export default SectionBreak
