import * as React from 'react'
import { Div } from 'glamorous'

import { tablet, mobile } from 'shared/media-queries'

const defaults = {
	fontSize: '38px',
	fontWeight: '700',
	lineHeight: '1.1',
}

const H2 = ({ additionalCss, ...other }) => {
	const css = {
		...defaults,
		[tablet]: {
			fontSize: '36px',
		},
		[mobile]: {
			fontSize: '32px',
		},
		...additionalCss,
	}
	return <Div {...other} css={css} />
}

export default H2
