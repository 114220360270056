// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { grayText, navy, lightBlue } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import { BulletList } from 'views/components'

const TitleAndInfoItems = ({
	items,
}: {
	items: Array<{
		title: string,
		wording: string | Array<string>,
		bullets: Array<String>,
	}>,
}) => (
	<Div>
		{items.map(({ title, wording, bullets }, index) => (
			<Div key={title} marginBottom={index === items.length - 1 ? 0 : '25px'}>
				<Div
					color={navy}
					fontWeight="700"
					css={{
						[mobile]: { fontSize: '16px' },
					}}
				>
					{title}
				</Div>
				<Div
					height="10px"
					css={{
						[mobile]: { height: '0' },
					}}
				/>
				<Div
					fontSize="18px"
					color={grayText}
					css={{
						paddingBottom: '10px',
						[mobile]: { lineHeight: '1.3', fontSize: '16px' },
					}}
				>
					{Array.isArray(wording) ? (
						<BulletList dotColor={lightBlue} items={wording} />
					) : (
						wording
					)}
				</Div>
				{!!bullets && (
					<BulletList dotColor={lightBlue} items={bullets} boldItems />
				)}
			</Div>
		))}
	</Div>
)

export default TitleAndInfoItems
