import React from 'react'
import { Button } from 'views/components'
import Spacer from 'components/spacer'
import styled from 'styled-components'
import { navy, grayText, cyan } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'

const HeaderText = styled.div`
	color: ${navy};
	font-weight: 700;
`
const SubtitleText = styled.div`
	font-size: 18px;
	color: ${grayText};
`

const RiskSuccess = () => (
	<div>
		<HeaderText>Your 2020 Construction Outlook is on its way!</HeaderText>
		<Spacer height="5px" />
		<SubtitleText>
			<>
				You’ll receive an email in a few minutes with a link to the report.
				<br />
				<br /> In the meantime, we'd love to introduce you to our risk product,
				TradeTapp. Builders across North America use TradeTapp to eliminate
				preventable risks during preconstruction, through a more advanced
				subcontractor prequalification process.
			</>
		</SubtitleText>
		<Spacer height="30px" />
		<Button
			label="Book a demo to learn more"
			backgroundColor={cyan}
			onClick={showRequestDemoModal}
		/>
	</div>
)

export default RiskSuccess
