declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import BBHeader from './components/bb-header'
import BBSuccess from './components/bb-success'
import { cyan } from 'shared/colors'
import styled from 'styled-components'
import { MarketoUrl, MarketoCode } from './constants'

const InAppDemoForm = styled(MarketoForm)`
	&.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
		background: ${cyan} !important;
		border-color: ${cyan} !important;
	}
`

const InAppDemoModal: React.FC = () => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1556)
		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [])

	return (
		<Modal maxWidth="400px">
			{!submitted && (
				<>
					<BBHeader />
					<InAppDemoForm id="mktoForm_1556" />
				</>
			)}
			{submitted && <BBSuccess />}
		</Modal>
	)
}

export default InAppDemoModal
