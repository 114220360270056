declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import RiskHeader from './components/risk-header'
import RiskSuccess from './components/risk-success'
import { lightBlue, grayText } from 'shared/colors'
import styled from 'styled-components'
import { MarketoUrl, MarketoCode } from './constants'

const TTForm = styled(MarketoForm)`
	.mktoForm {
		font-family: bc, sans-serif !important;
	}

	.mktoFieldDescriptor.mktoFormCol {
		margin-bottom: -10px !important;
	}

	.mktoField.mktoEmailField.mktoHasWidth.mktoRequired {
		height: 44px !important;
		font-weight: 300;
		color: ${grayText};
		border: 1px solid ${grayText};
		padding-left: 10px;
		margin-bottom: 10px;
		::placeholder {
			font-style: italic;
		}
		box-sizing: border-box;
		padding: 0 7px;
		font-size: 16px;
		background-color: white !important;
	}

	.mktoField.mktoHasWidth.mktoRequired {
		height: 44px !important;
		font-weight: 300;
		color: ${grayText};
		border: 1px solid ${grayText};
		padding-left: 10px;
		margin-bottom: 10px;
		::placeholder {
			font-style: italic;
		}
		box-sizing: border-box;
		padding: 0 7px;
		background-color: white !important;
	}

	.mktoField.mktoTextField.mktoHasWidth.mktoRequired {
		height: 44px !important;
		font-weight: 300;
		color: ${grayText};
		border: 1px solid ${grayText};
		padding-left: 10px;
		margin-bottom: 10px;
		::placeholder {
			font-style: italic;
		}
		box-sizing: border-box;
		padding: 0 7px;
		background-color: white !important;
	}

	.mktoField.mktoEmailField.mktoHasWidth.mktoRequired.mktoInvalid {
		height: 44px !important;
		font-weight: 300;
		color: ${grayText};
		border: 1px solid ${grayText};
		padding-left: 10px;
		margin-bottom: 10px;
		::placeholder {
			font-style: italic;
		}
		box-sizing: border-box;
		padding: 0 7px;
		font-size: 16px;
		background-color: white !important;
	}

	.mktoField.mktoTelField.mktoHasWidth.mktoRequired {
		height: 44px !important;
		font-weight: 300;
		color: ${grayText};
		border: 1px solid ${grayText};
		padding-left: 10px;
		margin-bottom: 10px;
		::placeholder {
			font-style: italic;
		}
		box-sizing: border-box;
		padding: 0 7px;
		background-color: white !important;
	}
	.mktoError {
		bottom: -15px !important;
	}
	.mktoFormRow {
		width: 100% !important;
		margin: 0 auto;
	}
	.mktoAsterix {
		display: none !important;
	}
	&.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
		width: 120px !important;
		background: ${lightBlue} !important;
		border-color: ${lightBlue} !important;
		:hover {
			background: ${lightBlue} !important;
			border-color: ${lightBlue} !important;
		}
		:active {
			background: ${lightBlue} !important;
			border-color: ${lightBlue} !important;
		}
		:visited {
			background: ${lightBlue} !important;
			border-color: ${lightBlue} !important;
		}
	}
`

const TTRiskReport: React.FC = () => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1820)
		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [])

	return (
		<Modal maxWidth="600px">
			{!submitted && (
				<>
					<RiskHeader />
					<TTForm id="mktoForm_1820" />
				</>
			)}
			{submitted && <RiskSuccess />}
		</Modal>
	)
}

export default TTRiskReport
