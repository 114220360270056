import styled from 'styled-components'

interface Props {
	size: number
}

const Spacer = styled.div`
	height: ${(props: Props) => props.size}px;
	width: ${(props: Props) => props.size}px;
	flex: 0 0 ${(props: Props) => props.size}px;
`

export default Spacer
