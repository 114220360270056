// @flow
import * as React from 'react'
import { Div } from 'glamorous'
import { Link } from 'gatsby'

import { BulletList, TitleAndInfoItems } from 'views/components'
import { SectionInfo } from './index'
import { brandBlue, lightBlue } from 'shared/colors'
import { laptop, tablet, mobile } from 'shared/media-queries'
import NewBadge from 'icons/new-badge.svg'

const InfoAndImage = ({
	image,
	imageOnRight,
	title,
	subtitle,
	info,
	centered,
	verticallyCentered,
	bulletItems,
	wordingWidth = '380px',
	spacerWidths = [],
	titleAndInfoItems,
	wideOnMobile = false,
	dotColor = lightBlue,
	ctaTitle,
	ctaLink,
	showNewBadge,
	stackedImageOnTop,
	additionalSubText,
}: {
	image: React.Node,
	imageOnRight?: boolean,
	title?: string,
	subtitle?: string,
	info: string,
	centered: boolean,
	verticallyCentered: boolean,
	bulletItems: Array<string>,
	wordingWidth?: string,
	spacerWidths: Array<string>,
	titleAndInfoItems?: Array<{ title: string, wording: string }>,
	wideOnMobile?: boolean,
	dotColor?: string,
	ctaTitle?: string,
	ctaLink?: string,
	showNewBadge: boolean,
	stackedImageOnTop?: boolean,
	additionalSubText?: React.Node,
}) => (
	<Div
		display="flex"
		justifyContent={imageOnRight ? undefined : 'flex-end'}
		alignItems={title ? 'center' : 'inherit'}
		css={{
			[tablet]: { flexDirection: 'column', alignItems: 'inherit' },
		}}
	>
		<Div
			flex="1 1 0%"
			order={imageOnRight ? '3' : '1'}
			css={{
				[tablet]: {
					order: stackedImageOnTop ? 0 : 3,
					textAlign: 'center',
					flex: '0 0 auto',
					marginBottom: stackedImageOnTop ? '30px' : '0px',
				},
				[mobile]: wideOnMobile ? { width: '100%' } : {},
			}}
		>
			{image}
		</Div>
		<Div
			order="2"
			width={spacerWidths[0] || '120px'}
			css={{
				flex: '0 0 auto',
				[laptop]: { width: spacerWidths[1] || '100px' },
				[tablet]: { height: '50px' },
				[mobile]: { height: '20px' },
			}}
		/>
		<Div
			display="flex"
			flexDirection="column"
			flex={`0 0 ${wordingWidth}`}
			order={imageOnRight ? '1' : '3'}
			css={{
				[tablet]: { flex: '0 0 auto', order: '1' },
			}}
			alignItems={centered ? 'center' : undefined}
			justifyContent={verticallyCentered ? 'center' : undefined}
			maxWidth="100%"
		>
			{showNewBadge && (
				<>
					<NewBadge />
					<Div height="15px" />
				</>
			)}
			<SectionInfo
				title={title}
				subtitle={subtitle}
				info={info}
				centered={centered}
				titleColor={dotColor}
			/>
			{bulletItems && info && <Div height="20px" />}
			{bulletItems && (
				<BulletList dotColor={dotColor} items={bulletItems} boldItems />
			)}
			{titleAndInfoItems && <TitleAndInfoItems items={titleAndInfoItems} />}
			{additionalSubText && additionalSubText}
			{ctaLink && (
				<Link
					to={ctaLink}
					css={{
						color: brandBlue,
						fontWeight: '700',
						paddingTop: '10px',
						[laptop]: {
							maxWidth: '300px',
						},
						[tablet]: {
							maxWidth: '500px',
						},
					}}
				>
					{ctaTitle} &raquo;
				</Link>
			)}
		</Div>
	</Div>
)

export default InfoAndImage
