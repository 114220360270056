import * as React from 'react'
import _ from 'lodash'
import ManagerComponent, { showModal, closeModal } from './manager-component'
import OMResponseModal from './om-response-modal'

import RequestDemoModal from './forms/request-demo'
import TTDemoRequest from './forms/tt-demo-request'
import TTRiskReport from './forms/tt-risk-report'
import InAppDemoModal from './forms/in-app'
import BCPCalcForm from './forms/bcp-calc'
import BBPCalcForm from './forms/bbp-calc'
import TradeTappCalcForm from './forms/tt-calc'

export const showRequestDemoModal = () => {
	const pathname = _.get(window, 'location.pathname', '')
	if (pathname.split('/')[1].includes('super-secret-page')) {
		return showModal(<InAppDemoModal />)
	}
	if (pathname.split('/')[1].includes('risk-trends')) {
		return showModal(<TTDemoRequest />)
	}
	const isTradeTappPage = pathname.split('/')[1].includes('tradetapp')
	return isTradeTappPage
		? showModal(<TTDemoRequest />)
		: showModal(<RequestDemoModal />)
}

export const showOMProModal = () => {
	showModal(<OMResponseModal />)
}

export const showTTDemoModal = () => {
	showModal(<TTDemoRequest />)
}

export const showRiskReportModal = () => {
	showModal(<TTRiskReport />)
}

export const showRoiReportModal = props => {
	const pathname = _.get(window, 'location.pathname', '')
	if (pathname.split('/')[1].includes('bc-pro')) {
		return showModal(<BCPCalcForm {...props} />)
	} else if (pathname.split('/')[1].includes('tradetapp')) {
		return showModal(<TradeTappCalcForm {...props} />)
	}
	return showModal(<BBPCalcForm {...props} />)
}

export { showModal, closeModal }

export default ManagerComponent
