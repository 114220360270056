import React from 'react'
import { Heading, SubHead, A } from './shared'

const BBHeader = () => (
	<div>
		<Heading>Get started with a Demo</Heading>
		<SubHead>
			Looking for help instead?{' '}
			<A href='https://buildingconnected.zendesk.com/hc/en-us?solvvy=true'>Contact support</A>
			<>
				<br />
				Trying to create an account?{' '}
				<A href="https://app.buildingconnected.com/create-account?utm_source=demo_form">
					Go here
				</A>
			</>
		</SubHead>
	</div>
)

export default BBHeader
