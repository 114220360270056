// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import Spacer from 'components/spacer'
import Button from 'components/button'
import PlayIcon from 'imgs/icons/play-button.svg'
import { tablet, mobile } from 'shared/media-queries'
import { showRequestDemoModal } from 'components/modal-manager'
import { DefaultHeading, DefaultSubHeading } from './default-elements'
import { WistiaVideoOverlay, Title } from 'views/components'
import { lightBlue } from 'shared/colors'

const Wording = ({
	heading,
	subHeading,
	videoId,
	ctaColor,
	videoCTAOnClick,
	wordingWidth,
	demoSource,
	demoButtonWording,
	demoButtonFullWidth,
	CTAs,
	introducing,
}: {
	heading: React.Node,
	subHeading: React.Node,
	ctaColor: string,
	wordingWidth: string,
	videoId?: string,
	videoCTAOnClick?: () => mixed,
	demoSource?: string,
	demoButtonWording: string,
	demoButtonFullWidth?: boolean,
	CTAs?: React.Node,
	introducing?: boolean,
}) => (
	<Div color="white" width={wordingWidth} maxWidth="100%">
		{!!introducing && (
			<>
				<Title wording="INTRODUCING" color={lightBlue} />
				<Div height="10px" />
			</>
		)}
		{typeof heading === 'string' ? (
			<DefaultHeading>{heading}</DefaultHeading>
		) : (
			heading
		)}
		<Div height="10px" />
		{typeof subHeading === 'string' ? (
			<DefaultSubHeading>{subHeading}</DefaultSubHeading>
		) : (
			subHeading
		)}
		<Spacer height="25px" />
		<Div
			display="flex"
			css={{
				[tablet]: { justifyContent: 'center' },
				[mobile]: { flexDirection: 'column' },
			}}
		>
			{!!CTAs ? (
				CTAs
			) : (
				<Button
					bgColor={ctaColor}
					style={{
						padding: '0 40px',
						width: demoButtonFullWidth ? '100%' : undefined,
					}}
					onClick={() => showRequestDemoModal(demoSource)}
				>
					{demoButtonWording}
				</Button>
			)}
			{videoId && <Spacer width="30px" height="10px" />}
			{videoId && (
				<Div position="relative" cursor="pointer" onClick={videoCTAOnClick}>
					<WistiaVideoOverlay videoId={videoId} />
					<Button
						bgColor="rgba(0,0,0,0)"
						border={`1px solid ${ctaColor}`}
						css={{
							[mobile]: { width: '100%' },
						}}
					>
						<Div display="flex" alignItems="center" justifyContent="center">
							<PlayIcon width="20px" height="20px" fill="white" />
							<Spacer width="7px" />
							Watch video
						</Div>
					</Button>
				</Div>
			)}
		</Div>
	</Div>
)

export default Wording
