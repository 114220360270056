import * as React from 'react'
import glamorous from 'glamorous'

import Modal from './modal'
import Spacer from '../spacer'

import { closeModal } from './index'
import { smallerThan } from 'shared/media-queries'
import { navy, gray80, blue } from 'shared/colors'
import { numberOfSubsOnBC } from 'shared/constants'

const ModalContent = glamorous.div({
	textAlign: 'center',
	maxWidth: '314px',
	fontWeight: 600,
})

const Heading = glamorous.h1({
	fontSize: '24px',
	color: navy,
	[smallerThan('xx-small')]: {
		fontSize: '22px',
	},
})

const SubHead = glamorous.h2({
	color: gray80,
	fontSize: '18px',
	[smallerThan('xx-small')]: {
		padding: '10px',
	},
})

const CTA = glamorous.a({
	color: blue,
	fontSize: '14px',
	cursor: 'pointer',
})

class OMResponseModal extends React.Component {
	render() {
		return (
			<Modal>
				<ModalContent>
					<Heading>Thank you for your response.</Heading>
					<Spacer height="9px" />
					<SubHead>
						Did you know that over {numberOfSubsOnBC} subcontractors use Bid
						Board Pro to manage all their bids in one place?
					</SubHead>
					<Spacer height="20px" />
					<CTA onClick={closeModal}>Got it!</CTA>
				</ModalContent>
			</Modal>
		)
	}
}

export default OMResponseModal
