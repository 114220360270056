import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import Spacer from 'components/spacer'
import { H2 } from 'views/components'
import InputItem from './input-item'
import { SectionSpacer } from './input-item/shared'
import { ROICalcInput } from './shared'
import { tablet } from 'shared/media-queries'

const MainContainer = styled.div`
	width: 100%;
	box-shadow: -2px 2px 10px 5px rgba(0, 0, 0, 0.1);
`

const TopSection = styled.div`
	height: 278px;
	padding: 30px;
	${tablet} {
		height: inherit;
	}
`

const InputContainer = styled.div`
	display: flex;
	justify-content: space-between;
	${tablet} {
		flex-direction: column;
	}
`

// prettier-ignore
interface ROIProps {
	defaultState: {[key: string]: any};
	inputs: Array<ROICalcInput>;
	resultsSection: (state: {}) => React.ReactNode;
	ranges: {[key: string]: {[key: string]: any}};
	forwardedRef?: any;
	title: React.ReactNode;
}

const ROICalculator: React.FC<ROIProps> = ({
	defaultState,
	inputs,
	resultsSection,
	ranges,
	forwardedRef,
	title,
}) => {
	const [calcState, updateCalcState] = useState(defaultState)

	const updateInput = (val: {}) => {
		const keys = Object.keys(val)
		if (keys.length > 1) {
			throw new Error('shouldnt update more than one input value at a time')
		}

		const keyToUpdate = keys[0]
		const value = val[keyToUpdate]
		const { min, max } = ranges[keyToUpdate]

		if (value < min) {
			updateCalcState({ ...calcState, [keyToUpdate]: min })
		} else if (value > max) {
			updateCalcState({ ...calcState, [keyToUpdate]: max })
		} else {
			updateCalcState({ ...calcState, [keyToUpdate]: value })
		}
	}

	return (
		<div ref={forwardedRef}>
			<H2 textAlign="center">{title}</H2>
			<Spacer height="30px" />
			<MainContainer>
				<TopSection>
					<InputContainer>
						{inputs.map(({ key, ...other }, index) => (
							<>
								<InputItem
									{...other}
									val={calcState[key]}
									index={index}
									inputKey={key}
									onChange={v => updateInput({ ...v })}
								/>
								<SectionSpacer />
							</>
						))}
					</InputContainer>
				</TopSection>
				{resultsSection(calcState)}
			</MainContainer>
		</div>
	)
}

export default ROICalculator
