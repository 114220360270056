import React from 'react'
import Spacer from 'components/spacer'
import styled from 'styled-components'
import { navy, grayText } from 'shared/colors'

const HeaderText = styled.div`
	color: ${navy};
	font-weight: 700;
`
const SubtitleText = styled.div`
	font-size: 18px;
	color: ${grayText};
`

const RoiCalcHeader = () => (
	<div>
		<HeaderText>Enter your email</HeaderText>
		<Spacer height="5px" />
		<SubtitleText>We’ll send you a copy of your results.</SubtitleText>
		<Spacer height="30px" />
	</div>
)

export default RoiCalcHeader
