import { css } from 'glamor'

const commonButtonClass = css({
	border: '0px',
	borderRadius: '2px',
	display: 'inline-block',
	fontWeight: 500,
	fontSize: 18,
	height: '44px',
	lineHeight: '44px',
	padding: '0 20px',
	cursor: 'pointer',

	color: '#fff',
	':hover': { color: '#fff' },
	':active': { color: '#fff' },
	':visited': { color: '#fff' },
})

export default commonButtonClass
