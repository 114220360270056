import React from 'react'
import { Heading, A } from './shared'

const TTSuccess = () => (
	<div id="request-a-demo-success-modal">
		<Heading>Thank you for your interest in TradeTapp!</Heading>
		<p>
			One of our representatives will reach out to you shortly. If you already
			have an account, you can{' '}
			<A href="https://app.buildingconnected.com">sign in here</A>.
		</p>
		<p>
			If you have any questions about responding to bid invites, please feel
			free to contact{' '}
			<A href="mailto:support@tradetapp.com">support@tradetapp.com</A>.
		</p>
	</div>
)

export default TTSuccess
