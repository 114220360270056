import styled from 'styled-components'

const HangingQuote = styled.div`
	&::before {
		content: '\\201C';
		position: relative;
		left: -0.1em;
		margin-left: -0.35em;
	}
	&::after {
		content: '\\201D';
	}
`

export default HangingQuote
