// @flow
import * as React from 'react'
import styled from 'styled-components'

import { grayText } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const Ul = styled.ul`
	list-style: none;
	padding-left: 1em;
	margin: 0;
`
const Li = styled.li`
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: ${props => props.boldItems && 600};
	color: ${props => props.boldItems && props.boldItemsColor};
	&::before {
		content: '•';
		color: ${props => props.dotColor};
		font-weight: bold;
		display: inline-block;
		width: 1em;
		margin-left: -1em;
	}
	${mobile} {
		font-size: 16px;
	}
`

const BulletList = ({
	dotColor,
	items,
	boldItems,
	boldItemsColor = grayText,
	additionalLiCss = {},
	additionalUlCss = {},
}: {
	dotColor: string,
	items: Array<string>,
	boldItems: boolean,
	boldItemsColor: string,
	additionalLiCss?: {},
	additionalUlCss?: {},
}) => {
	return (
		<Ul css={additionalUlCss}>
			{items.map((item, i) => (
				<Li
					key={i}
					boldItems={boldItems}
					boldItemsColor={boldItemsColor}
					dotColor={dotColor}
					css={additionalLiCss}
				>
					{item}
				</Li>
			))}
		</Ul>
	)
}

export default BulletList
