import React from 'react'
import { Heading, A } from './shared'

const BBSuccess = () => (
	<div id="request-a-demo-success-modal">
		<Heading>Thank you for your interest in BuildingConnected!</Heading>
		<p>
			One of our representatives will reach out to you shortly. If you already
			have an account, you can{' '}
			<A href="https://app.buildingconnected.com">sign in here</A>.
		</p>
		<p>
			If you have any questions about responding to bid invites, please feel
			free to contact{' '}
			<A href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true">
				support
			</A>
			.
		</p>
	</div>
)

export default BBSuccess
