import * as React from 'react'
import Slider from '../slider'
import {
	Section,
	Input,
	PreWordingSpacer,
	PostWordingSpacer,
	getNumbersFromInputEvent,
} from './shared'

// prettier-ignore
interface InputItemProps {
  onChange: ({}) => (any);
  val: string;
	inputKey: string;
	wording: React.ReactNode;
	min: number;
	max: number;
  step: number;
  index: number;
  formatNumberDisplay?: (number) => string;
}

const InputItem: React.FC<InputItemProps> = ({
	onChange,
	inputKey,
	wording,
	val,
	min,
	max,
	step,
	index,
	formatNumberDisplay,
}) => (
	<Section>
		<Input
			value={formatNumberDisplay ? formatNumberDisplay(val) : val}
			onChange={e => {
				const value = getNumbersFromInputEvent(e)
				onChange({ [inputKey]: value })
			}}
			tabIndex={index}
		/>
		<PreWordingSpacer />
		{wording}
		<PostWordingSpacer />
		<Slider
			value={val}
			onChange={(_, value) => onChange({ [inputKey]: value })}
			step={step}
			min={min}
			max={max}
		/>
	</Section>
)

export default InputItem
