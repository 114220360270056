// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { SectionInfo } from 'views/components'
import Card from './card'
import { BlueImage, GreenImage } from './default-components'

type Props = {
	title: string,
	subtitle: string,
	cards: Array<any>,
}

class ResourceHub extends React.PureComponent<Props> {
	render() {
		const { title, subtitle, cards } = this.props
		return (
			<div>
				<SectionInfo subtitle={title} info={subtitle} centered />
				<Div height="30px" />
				<Div
					display="flex"
					justifyContent="center"
					flexWrap="wrap"
					margin="-15px"
				>
					{cards.map(cardInfo => (
						<Card key={cardInfo.wording} {...cardInfo} />
					))}
				</Div>
			</div>
		)
	}

	static DefaultBlueImage = BlueImage
	static DefaultGreenImage = GreenImage
}

export default ResourceHub
