// @flow
import * as React from 'react'
import { Img, Div } from 'glamorous'

import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { grayText } from 'shared/colors'
import { mobile } from 'shared/media-queries'
import { HangingQuote } from 'views/components'

const LargeQuote = ({
	image1x,
	image2x,
	image3x,
	quote,
	name,
}: {
	image1x: string,
	image2x: string,
	image3x: string,
	quote: string,
	name: string,
}) => (
	<Div textAlign="center">
		<Img
			height="140px"
			width="140px"
			src={image1x}
			srcSet={`
				${image2x} 2x,
				${image3x} 3x,
			`}
		/>
		<Spacer height="25px" />
		<H2
			maxWidth="900px"
			margin="0 auto"
			additionalCss={{
				[mobile]: { fontSize: '20px' },
			}}
		>
			<HangingQuote>{quote}</HangingQuote>
		</H2>
		<Spacer height="30px" />
		<Div
			fontSize="16px"
			fontWeight="700"
			letterSpacing="1px"
			textTransform="uppercase"
			color={grayText}
			css={{
				[mobile]: { fontSize: '12px', letterSpacing: '0' },
			}}
		>
			{name}
		</Div>
	</Div>
)

export default LargeQuote
