import styled from 'styled-components'

import { grayText, lightBlue } from 'shared/colors'

export const Heading = styled.h1`
	font-size: 27px;
	margin: 0 0 10px 0;
	text-align: center;
`

export const SubHead = styled.p`
	font-size: 13px;
	font-weight: 600;
	margin: 0 auto 25px !important;
	text-align: center;
	color: ${grayText};
`

export const A = styled.a`
	color: ${lightBlue};
	:hover {
		color: ${lightBlue};
	}
	:active {
		color: ${lightBlue};
	}
	:visited {
		color: ${lightBlue};
	}
`
