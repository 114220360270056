import * as React from 'react'
import styled from 'styled-components'

import { laptop, tablet, mobile } from 'shared/media-queries'
import { grayText, gray02, gray20, navy } from 'shared/colors'

export const Section = styled.div`
	flex: 1 1 0%;
	${tablet} {
		display: flex;
		align-items: center;
		flex: auto;
	}
	${mobile} {
		flex-wrap: wrap;
	}
`
export const SectionSpacer = styled.div`
	flex: 0 0 40px;
	${laptop} {
		flex: 0 0 30px;
	}
`
export const Input = styled.input`
	font-variant-numeric: tabular-nums;
	width: 100%;
	height: 58px;
	font-size: 38px;
	background: ${gray02};
	border-radius: 2px;
	font-weight: 700;
	color: ${navy};
	border: none;
	text-align: center;
	box-shadow: 0 1px 0 0 ${gray20};
	min-width: 0;
	${laptop} {
		font-size: 36px;
	}
	${tablet} {
		flex: 0 0 125px;
	}
	${mobile} {
		font-size: 30px;
	}
`
export const PreWordingSpacer = styled.div`
	height: 20px;
	${tablet} {
		flex: 0 0 25px;
	}
	${mobile} {
		flex: 0 0 10px;
	}
`
export const Wording = styled.div`
	color: ${grayText};
	font-size: 18px;
	text-align: center;
	height: 47px;
	${tablet} {
		text-align: left;
		flex: 0 0 164px;
		height: inherit;
	}
	${mobile} {
		flex: 1 1 0%;
	}
`
export const WordingBreak = styled.br`
	${laptop} {
		display: none;
	}
`
export const PostWordingSpacer = styled.div`
	height: 50px;
	${tablet} {
		flex: 0 0 30px;
	}
	${mobile} {
		display: none;
	}
`

export const SliderSpacer = styled.div`
	height: 50px;
`

export const Span = styled.span`
	position: relative;
	top: 2px;
`

export const getNumbersFromInputEvent = (
	e: React.KeyboardEvent | React.ChangeEvent
) => {
	const value = e.target instanceof HTMLInputElement ? e.target.value : ''
	const justTheNumbersPlease = value.replace(/\D/g, '')
	return Number(justTheNumbersPlease)
}

export const isBackspacingTheEndOfInput = (e: React.KeyboardEvent) => {
	if (e.key === 'Backspace' && e.target instanceof HTMLInputElement) {
		const { value, selectionStart, selectionEnd } = e.target
		if (selectionStart === selectionEnd && selectionStart === value.length) {
			return true
		}
	}
	return false
}

export const getNumbersFromKeyDownBackspaceWithEndingSymbol = (
	e: React.KeyboardEvent
) => {
	const numberString = String(getNumbersFromInputEvent(e))
	const updatedNumbers =
		numberString.length > 1
			? numberString.slice(0, -1) // account for backspace beyond ending symbol
			: 0
	return Number(updatedNumbers)
}
