// @flow
import * as React from 'react'
import { Div, Video } from 'glamorous'

import ContentWrapper from '../content-wrapper'
import Wording from './wording'
import { laptop, tablet, mobile } from 'shared/media-queries'
import { navy, cyan } from 'shared/colors'

const Hero = ({
	backgroundColor = navy,
	backgroundVideo,
	loopVideo = true,
	backgroundImage,
	ctaColor = cyan,
	heading,
	subHeading,
	videoId,
	videoCTAOnClick,
	wordingWidth = '400px',
	demoSource,
	demoButtonWording = 'Get a demo',
	demoButtonFullWidth,
	CTAs,
	leftAlign,
	introducing,
}: {
	backgroundColor?: string,
	backgroundVideo?: {
		webm?: string,
		mp4?: string,
	},
	loopVideo: Boolean,
	backgroundImage?: React.Node,
	ctaColor?: string,
	heading: string,
	subHeading: string,
	videoId?: string,
	videoCTAOnClick?: () => mixed,
	wordingWidth?: string,
	demoSource?: string,
	demoButtonWording?: string,
	demoButtonFullWidth?: boolean,
	CTAs?: React.Node,
	leftAlign?: boolean,
	introducing?: boolean,
}) => (
	<Div
		position="relative"
		overflow="hidden"
		backgroundColor={backgroundColor}
		display="flex"
		justifyContent="center"
		css={{
			height: '600px',
			[laptop]: { height: '500px' },
			[tablet]: { height: '400px' },
			[mobile]: { height: '380px' },
		}}
	>
		{backgroundVideo && (
			<Video
				position="absolute"
				height="100%"
				autoPlay
				muted
				playsInline
				loop={loopVideo}
			>
				<source src={backgroundVideo.webm} type="video/webm" />
				<source src={backgroundVideo.mp4} type="video/mp4" />
			</Video>
		)}
		{backgroundImage && (
			<Div position="absolute" height="100%" width="100%" maxWidth="1440px">
				{backgroundImage}
			</Div>
		)}
		<ContentWrapper position="relative" margin="none">
			<Div
				height="100%"
				display="flex"
				alignItems="center"
				justifyContent={leftAlign ? 'flex-start' : 'flex-end'}
				css={{
					[tablet]: { justifyContent: 'center', textAlign: 'center' },
				}}
			>
				<Wording
					heading={heading}
					subHeading={subHeading}
					videoId={videoId}
					ctaColor={ctaColor}
					videoCTAOnClick={videoCTAOnClick}
					wordingWidth={wordingWidth}
					demoSource={demoSource}
					demoButtonWording={demoButtonWording}
					demoButtonFullWidth={demoButtonFullWidth}
					CTAs={CTAs} // This overrides the default demo button
					introducing={introducing}
				/>
			</Div>
		</ContentWrapper>
	</Div>
)

export default Hero
