// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { laptop } from 'shared/media-queries'
import { cyan, navy, brandBlue } from 'shared/colors'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'

const Card = ({
	image,
	title,
	wording,
	cta,
	titleColor,
}: {
	image: React.Node,
	title: string,
	wording: React.Node,
	cta: React.Node,
	titleColor: string,
}) => (
	<Div
		height="400px"
		width="380px"
		display="flex"
		flexDirection="column"
		flex="0 0 380px"
		boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.3)"
		margin="15px"
		css={{
			[laptop]: {
				minWidth: '0',
				height: '350px',
				width: '300px',
				flex: '0 1 300px',
				margin: '8px',
			},
		}}
	>
		{!!image && (
			<Div
				css={{
					height: '215px',
					[laptop]: { height: '170px' },
				}}
			>
				{image}
			</Div>
		)}
		<Div display="flex" flexDirection="column" padding="20px" flex="1 1 0%">
			<Div
				fontSize="16px"
				color={titleColor}
				fontWeight="600"
				textTransform="uppercase"
				letterSpacing="1px"
			>
				{title}
			</Div>
			<Div height="10px" />
			<Div
				fontWeight="600"
				flex="1 1 0%"
				lineHeight="1.2"
				fontSize="20px"
				color={navy}
			>
				{wording}
			</Div>
			{cta && (
				<Div fontSize="18px" color={brandBlue}>
					{cta} &raquo;
				</Div>
			)}
		</Div>
	</Div>
)

const MaybeWrappedResourceHubCard = ({
	videoId,
	href,
	image,
	title,
	wording,
	cta,
	titleColor = cyan,
}: {
	videoId?: string,
	href?: string,
	image: React.Node,
	title: string,
	wording: React.Node,
	cta: React.Node,
	titleColor?: string,
}) => {
	const cardProps = { image, title, wording, cta, titleColor }

	if (videoId) {
		return (
			<Div position="relative" cursor="pointer">
				<WistiaVideoOverlay videoId={videoId} />
				<Card {...cardProps} />
			</Div>
		)
	} else if (href) {
		return (
			<a href={href} target="_blank">
				<Card {...cardProps} />
			</a>
		)
	}

	return <Card {...cardProps} />
}

export default MaybeWrappedResourceHubCard
