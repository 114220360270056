import * as React from 'react'
import { Div } from 'glamorous'

let instance

class ModalManager extends React.Component {
	constructor() {
		super()
		instance = this
		this.state = {
			modal: null,
		}

		this.showModal = modal => {
			this.setState({ modal })
			if (document && document.body) {
				document.body.style.overflow = 'hidden'
			}
		}

		this.closeModal = () => {
			this.setState({ modal: null })
			if (document && document.body) {
				document.body.style.overflow = 'visible'
			}
		}
	}

	render() {
		const { modal } = this.state
		return (
			<Div
				alignItems="flex-start"
				background={modal ? 'rgba(0, 0, 0, 0.25)' : ''}
				bottom={0}
				data-modal-manager
				display={modal ? 'flex' : 'none'}
				justifyContent="center"
				left={0}
				overflowY="auto"
				paddingTop="10vh"
				position="fixed"
				right={0}
				top={0}
				zIndex="100"
				style={this.props.style || null}
				onClick={e => {
					if (e.target.dataset.modalManager) {
						this.closeModal()
						closeModal()
					}
				}}
				css={{
					WebkitOverflowScrolling: 'touch',
				}}
			>
				{modal}
			</Div>
		)
	}

	componentDidMount() {
		window.addEventListener('keydown', e => {
			// Listen for the escape key
			if (e.keyCode === 27) {
				closeModal()
			}
		})
	}
}

export function showModal(modal) {
	instance.showModal(modal)
}

export function closeModal() {
	instance.closeModal()
}

export default ModalManager
