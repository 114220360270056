import * as React from 'react'
import { Slider } from '@material-ui/core'
import styled from 'styled-components'
import SliderButton from 'icons/slider-button.svg'
import { withStyles } from '@material-ui/core/styles'

import { navy } from 'shared/colors'
import { laptop, tablet, mobile } from 'shared/media-queries'

const SliderComponent: React.FC<{}> = props => (
	<span {...props}>
		<SliderButton />
	</span>
)

const thumbDefaults = {
	height: '40px',
	width: '40px',
	marginTop: '-18px',
	marginLeft: '-18px',
}

const materialStyles = {
	thumb: {
		...thumbDefaults,
		[laptop]: {
			height: '30px',
			width: '30px',
			marginTop: '-13px',
			marginLeft: '-13px',
		},
		[tablet]: thumbDefaults,
	},
	track: { height: '4px', borderRadius: '2px' },
	root: { color: navy },
}

const SliderDiv = styled.div`
	width: 100%;
	${mobile} {
		padding-top: 25px;
	}
`
// prettier-ignore
interface SliderProps {
	classes: any;
	value: any;
	step: number;
	min: number;
	max: number;
	onChange: (event: any, value: any) => ({})
}

const DefaultSlider: React.FC<SliderProps> = ({ classes, ...other }) => (
	<SliderDiv>
		<Slider
			classes={{
				thumb: classes.thumb,
				track: classes.track,
				root: classes.root,
			}}
			ThumbComponent={SliderComponent}
			step={1}
			min={1}
			max={100}
			{...other}
		/>
	</SliderDiv>
)

export default withStyles(materialStyles)(DefaultSlider)
