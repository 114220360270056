import React from 'react'
import { Button } from 'glamorous'

import { lightBlue } from 'shared/colors'

const BCButton = ({ label, ...other }) => (
	<Button
		border="0px"
		borderRadius="2px"
		backgroundColor={lightBlue}
		display="inline-block"
		fontWeight="500"
		fontSize="18px"
		height="44px"
		lineHeight="44px"
		padding="0 20px"
		cursor="pointer"
		color="#fff"
		css={{
			':hover': { color: '#fff' },
			':active': { color: '#fff' },
			':visited': { color: '#fff' },
		}}
		{...other}
	>
		{label}
	</Button>
)

export default BCButton
