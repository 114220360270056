import styled from 'styled-components'

import { grayText } from 'shared/colors'

export default styled.input`
	width: 100%;
	height: 44px;
	font-weight: 300;
	color: ${grayText};
	border: 1px solid ${grayText};
	font-size: 16px;
	padding-left: 10px;
	border-radius: 2px;
`
