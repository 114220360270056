// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { grayText, navy } from 'shared/colors'
import Plus from 'icons/plus.svg'
import Minus from 'icons/minus.svg'
import { laptop, mobile } from 'shared/media-queries'

type Props = {
	faqs: Array<{
		question: string,
		answer: React.Node,
	}>,
	borderColor: string,
	numbered: boolean,
}
type State = { openIndex: number }

class FAQTable extends React.Component<Props, State> {
	state = { openIndex: 0 }

	render() {
		const { faqs, borderColor, numbered } = this.props
		const { openIndex } = this.state

		return (
			<Div>
				{faqs.map(({ question, answer }, i) => (
					<Div
						key={i}
						padding="35px"
						border={`2px solid ${borderColor}`}
						borderBottom={i < faqs.length - 1 ? 'none' : undefined}
						cursor="pointer"
						css={{
							[laptop]: { padding: '25px' },
						}}
						onClick={() => {
							const newOpenIndex = openIndex === i ? -1 : i
							this.setState({ openIndex: newOpenIndex })
						}}
					>
						<Div
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Div
								fontWeight="700"
								css={{ [mobile]: { 'max-width': '297px' } }}
							>
								{numbered ? `${i + 1}. ${question}` : `Q: ${question}`}
							</Div>
							<Div display="flex">
								{openIndex === i ? <Minus /> : <Plus fill={navy} />}
							</Div>
						</Div>
						{openIndex === i && (
							<Div
								borderTop={`4px dotted ${borderColor}`}
								marginTop="15px"
								paddingTop="15px"
								fontSize="18px"
								color={grayText}
							>
								{answer}
							</Div>
						)}
					</Div>
				))}
			</Div>
		)
	}
}

export default FAQTable
