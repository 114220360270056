import React from 'react'
import styled from 'styled-components'
import { navy, grayText } from 'shared/colors'

const Title = styled.div`
	color: ${navy};
	font-weight: 700;
	margin-bottom: 5px;
`
const Text = styled.div`
	font-size: 18px;
	color: ${grayText};
	margin-bottom: 20px;
`

const RiskHeader = () => (
	<div>
		<Title>Download the 2020 U.S. Construction Risk Outlook</Title>
		<Text>
			Enter your email address, name, and company, and we'll send you a PDF copy
			of the report.
		</Text>
	</div>
)

export default RiskHeader
