// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { cyan, navy, gray80 } from 'shared/colors'
import { mobile } from 'shared/media-queries'

type SectionType = {
	icon: React.Node,
	heading: string,
	headingColor?: string,
	title?: string,
	wording: string,
}
const Section = ({
	icon,
	heading,
	headingColor,
	title,
	wording,
}: SectionType): React.Node => (
	<Div
		display="flex"
		css={{
			[mobile]: {
				flexDirection: 'column',
			},
		}}
	>
		<Div flex="0 0 120px" minWidth="0" textAlign="center">
			{icon}
		</Div>
		<Div
			flex="0 0 45px"
			css={{
				[mobile]: {
					display: 'none',
				},
			}}
		/>
		<Div
			css={{
				[mobile]: {
					textAlign: 'center',
				},
			}}
		>
			<Div
				fontSize="38px"
				color={headingColor}
				lineHeight="38px"
				paddingBottom="5px"
			>
				{heading}
			</Div>
			{title && (
				<Div fontSize="20px" fontWeight="700" color={navy} paddingBottom="5px">
					{title}
				</Div>
			)}
			<Div fontSize="18px" color={gray80}>
				{wording}
			</Div>
		</Div>
	</Div>
)

const VerticalInfo = ({
	sections,
	headingColor = cyan,
}: {
	sections: Array<SectionType>,
	headingColor: string,
}) => (
	<div>
		<Div display="flex" justifyContent="center">
			<Div maxWidth="555px">
				{sections.map((section, index) => (
					<div key={section.heading}>
						<Section
							{...section}
							headingColor={headingColor}
							key={section.heading}
						/>
						{index < sections.length - 1 && <Div height="50px" />}
					</div>
				))}
			</Div>
		</Div>
	</div>
)

export default VerticalInfo
