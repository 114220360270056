// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { H2, HangingQuote, ContentWrapper } from 'views/components'
import { grayText } from 'shared/colors'
import { laptop, tablet } from 'shared/media-queries'

const Card = ({ image, quote, name, title, company }) => (
	<Div
		flex="0 1 383px"
		backgroundColor="white"
		padding="0 30px 35px 50px"
		boxShadow="-2px 2px 10px 5px rgba(0, 0, 0, 0.1)"
		height="100%"
		css={{
			[laptop]: { padding: '0 30px 30px 30px' },
			[tablet]: { flex: '0 0 auto' },
		}}
	>
		<Div margin="-60px 0 0 -30px">{image}</Div>
		<Div fontSize="24px" lineHeight="1.5">
			<HangingQuote>{quote}</HangingQuote>
		</Div>
		<Div height="15px" />
		<Div fontSize="18px" color={grayText}>
			<Div fontWeight="700">{name}</Div>
			<Div>{title}</Div>
			<Div>{company}</Div>
		</Div>
	</Div>
)

type TestimonialData = {
	image: React.Node,
	quote: string,
	name: string,
	title: string,
	company: string,
}

type TestimonialProps = {
	header: string,
	data: Array<TestimonialData>,
}

const Testimonials = ({ header, data }: TestimonialProps) => (
	<ContentWrapper>
		<H2 textAlign="center">{header}</H2>
		<Div height="100px" />
		<Div
			display="flex"
			justifyContent="center"
			css={{
				[tablet]: { flexDirection: 'column' },
			}}
		>
			{data.map(({ image, quote, name, title, company }) => {
				return (
					<>
						<Card
							key={name}
							image={image}
							quote={quote}
							name={name}
							title={title}
							company={company}
						/>
						<Div
							flex="0 0 30px"
							key={title}
							css={{
								[tablet]: { flex: '0 0 90px' },
								'&:last-child': {
									flex: `0 0 0px`,
								},
							}}
						/>
					</>
				)
			})}
		</Div>
	</ContentWrapper>
)

export default Testimonials
