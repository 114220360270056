import styled from 'styled-components'

import { tablet, mobile } from 'shared/media-queries'

export default styled.h1`
	max-width: 100%;
	font-size: 54px;
	font-weight: 700;
	line-height: 1.1;
	margin: 0;
	${tablet} {
		font-size: 36px;
	}
	${mobile} {
		font-size: 32px;
	}
`
