import * as React from 'react'
import { Button, Div } from 'glamorous'

import { closeModal } from './index'
import { smallerThan, largerThan } from '../../shared/media-queries'
import CloseIcon from '../../imgs/icons/close.svg'

const Modal = ({ children, id, maxWidth }) => {
	return (
		<Div
			id={id}
			background="#fff"
			maxWidth={maxWidth || '100%'}
			position="relative"
			boxShadow="0 5px 15px rgba(0,0,0,.5)"
			borderRadius="2px"
			maxHeight="100%"
			overflow="scroll"
			css={{
				msOverflowStyle: 'none', // IE 10+
				overflow: '-moz-scrollbars-none', // Firefox
				'::-webkit-scrollbar': {
					display: 'none', // Safari and Chrome
				},
				[smallerThan('xx-small')]: {
					padding: '30px',
				},
				[largerThan('xx-small')]: {
					padding: '50px',
				},
			}}
		>
			<Button
				border="none"
				cursor="pointer"
				padding={0}
				margin={0}
				background="transparent"
				position="absolute"
				onClick={closeModal}
				top={0}
				right={0}
				color="#b2b2b2"
				css={{
					'&:hover': {
						color: '#595959',
					},
					[smallerThan('xx-small')]: {
						width: '30px',
						height: '50px',
					},
					[largerThan('xx-small')]: {
						width: '50px',
						height: '50px',
					},
				}}
			>
				<CloseIcon width="16px" height="16px" fill="currentColor" />
			</Button>
			{children}
		</Div>
	)
}

export default Modal
