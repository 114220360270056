import React from 'react'

import commonButtonClass from './common-button-class'

const Button = ({
	children,
	bgColor,
	color,
	onClick,
	id,
	border,
	borderRadius,
	style,
	className,
}) => {
	return (
		<button
			id={id}
			className={`${commonButtonClass} ${className}`}
			style={{
				backgroundColor: bgColor,
				color,
				border,
				borderRadius,
				...style,
			}}
			onClick={onClick}
		>
			{children}
		</button>
	)
}

export default Button
