// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { mobile } from 'shared/media-queries'

const ContentWrapper = ({ children, ...other }: { children: React.Node }) => (
	<Div
		width="90%"
		height="100%"
		margin="0 auto"
		maxWidth="1206px"
		css={{
			[mobile]: { width: '95%' },
		}}
		{...other}
	>
		{children}
	</Div>
)

export default ContentWrapper
