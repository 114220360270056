// @flow
import * as React from 'react'
import { Div, Span } from 'glamorous'

import { navy, cyan } from 'shared/colors'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { showRequestDemoModal } from 'components/modal-manager'
import { tablet, mobile, smallerThan } from 'shared/media-queries'
import { sitePath } from 'shared/constants'

const Footer = ({
	wording,
	wordingMaxWidth = '650px',
	subtext,
	demoButtonWording = 'Get a demo',
	demoButtonPadding = '40px',
	ctaColor = cyan,
	showCreateAccount,
	demoSource,
	additionalCTAs,
	showLearnMoreBBP,
	noDemo,
}: {
	wording: string,
	wordingMaxWidth?: string,
	subtext?: string,
	demoButtonWording?: string,
	demoButtonPadding?: string,
	ctaColor?: string,
	showCreateAccount?: boolean,
	showLearnMoreBBP?: boolean,
	demoSource?: string,
	additionalCTAs?: Array<React.Node>,
	noDemo?: Boolean,
}) => (
	<Div
		height="475px"
		backgroundColor={navy}
		textAlign="center"
		display="flex"
		flexDirection="column"
		alignItems="center"
		justifyContent="center"
		padding="20px"
		css={{
			[tablet]: { height: '420px' },
			[mobile]: { height: '320px' },
			[smallerThan(425)]: {
				height: '420px',
			},
		}}
	>
		<Div
			color="white"
			maxWidth={wordingMaxWidth}
			margin="0 auto"
			fontSize="54px"
			fontWeight="700"
			lineHeight="1.1"
			css={{
				[mobile]: { fontSize: '32px' },
			}}
		>
			{wording}
		</Div>
		{subtext && (
			<Div
				paddingTop="20px"
				color="white"
				maxWidth={wordingMaxWidth}
				fontSize="24px"
				css={{
					[mobile]: { fontSize: '16px' },
				}}
			>
				{subtext}
			</Div>
		)}
		<Spacer height="30px" />
		<Div
			display="flex"
			css={{
				[mobile]: { flexDirection: 'column' },
			}}
		>
			{!noDemo && (
				<Button
					bgColor={ctaColor}
					onClick={() => showRequestDemoModal(demoSource)}
					css={{
						padding: `0 ${demoButtonPadding}`,
						[tablet]: { padding: '0 100px' },
						[mobile]: { padding: '0 40px' },
					}}
				>
					{demoButtonWording}
				</Button>
			)}
			{additionalCTAs}
		</Div>
		{(showCreateAccount || showLearnMoreBBP) && <Spacer height="15px" />}
		{showCreateAccount && (
			<Div color="white" fontSize="18px">
				<Span>Need an account? </Span>
				<a css={{ color: ctaColor }} href={`${sitePath}/create-account`}>
					Create one now.
				</a>
			</Div>
		)}
		{showLearnMoreBBP && (
			<Div color="white" fontSize="18px">
				<Span>Learn more about </Span>
				<a css={{ color: cyan }} href={`/bid-board/`} target="_blank">
					Bid Board Pro
				</a>
			</Div>
		)}
	</Div>
)

export default Footer
