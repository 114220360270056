import React from 'react'
import { Button } from 'views/components'
import Spacer from 'components/spacer'
import styled from 'styled-components'
import { navy, grayText } from 'shared/colors'
import { showRequestDemoModal } from 'components/modal-manager'

const HeaderText = styled.div`
	color: ${navy};
	font-weight: 700;
`
const SubtitleText = styled.div`
	font-size: 18px;
	color: ${grayText};
`

const RoiCalcSuccess = ctaColor => (
	<div>
		<HeaderText>Nailed it!</HeaderText>
		<Spacer height="5px" />
		<SubtitleText>
			We’ve sent you a copy of your personalized ROI report.
		</SubtitleText>
		{ctaColor && (
			<>
				<Spacer height="30px" />
				<Button
					label="Get a demo and a quote"
					backgroundColor={ctaColor}
					onClick={showRequestDemoModal}
				/>
			</>
		)}
	</div>
)

export default RoiCalcSuccess
