declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import RoiCalcHeader from './components/roi-calc-header'
import RoiCalcSuccess from './components/roi-calc-success'
import { cyan } from 'shared/colors'
import { MarketoUrl, MarketoCode } from './constants'

// prettier-ignore
interface Props {
	timeSavedPerYear: number;
	subcontractors: number;
	overheadPerSub: number;
	hoursSavedPerSub: number;
	hoursSpent: number;
	potentialGrowth: number;
	wage: number;
	riskManagers: number;
}

const TradeTappCalcForm: React.FC<Props> = ({
	timeSavedPerYear,
	hoursSpent,
	overheadPerSub,
	hoursSavedPerSub,
	subcontractors,
	potentialGrowth,
	wage,
	riskManagers,
}) => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 2495)

		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			// Set the values of the form that are hidden to the user
			form.setValues({
				bc_hours_spent_qualifying_one_sub_input_tt_roi_calc: hoursSpent,
				bc_avg_annual_sub_quals_input_tt_roi_calc: subcontractors,
				bc_avg_time_qualifying_sub_with_tt_output_tt_roi_calc: hoursSavedPerSub,
				bc_avg_annual_time_saved_qualifying_subs_with_tt_output_tt_roi_calc: timeSavedPerYear,
				bc_overhead_cost_savings_per_sub_qual_output_tt_roi_calc: overheadPerSub,
				bc_annual_overhead_cost_savings_for_qual_output_tt_roi_calc: potentialGrowth,
				bc_estimator_hourly_wage_input_tt_roi_calc: wage,
				bc_avg_number_risk_managers_input_tt_roi_calc: riskManagers,
			})

			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [
		timeSavedPerYear,
		hoursSpent,
		overheadPerSub,
		hoursSavedPerSub,
		subcontractors,
		potentialGrowth,
		wage,
		riskManagers,
	])

	return (
		<Modal maxWidth="400px">
			{submitted ? (
				<RoiCalcSuccess />
			) : (
				<>
					<RoiCalcHeader />
					<MarketoForm id="mktoForm_2495" />
				</>
			)}
		</Modal>
	)
}

export default TradeTappCalcForm
