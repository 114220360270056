// @flow

import * as React from 'react'
import { Div } from 'glamorous'

import { H2 } from 'views/components'
import { grayText } from 'shared/colors'
import { tablet } from 'shared/media-queries'

const InfoSection = ({
	content,
}: {
	content: Array<{ icon: React.Node, title: string, text: string }>,
}) => (
	<Div display="flex" flexWrap="wrap" margin="-20px" justifyContent="center">
		{content.map(({ icon, title, text }) => (
			<Div
				flex="1 1 280px"
				margin="20px"
				css={{
					[tablet]: { maxWidth: '310px' },
				}}
			>
				{icon}
				<Div fontWeight="700">{title}</Div>
				<Div fontSize="18px" color={grayText}>
					{text}
				</Div>
			</Div>
		))}
	</Div>
)

const IconAndInfoGrid = ({
	sections,
}: {
	sections: Array<{
		title: string,
		content: Array<{ icon: React.Node, title: string, text: string }>,
	}>,
}) => (
	<Div>
		{sections.map(({ title, content }, i) => (
			<>
				<Div textAlign="center">
					<H2>{title}</H2>
					<Div height="50px" />
					<InfoSection content={content} />
				</Div>
				{i < sections.length - 1 && <Div height="100px" />}
			</>
		))}
	</Div>
)

export default IconAndInfoGrid
