declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import RoiCalcHeader from './components/roi-calc-header'
import RoiCalcSuccess from './components/roi-calc-success'
import { cyan } from 'shared/colors'
import { MarketoUrl, MarketoCode } from './constants'

// prettier-ignore
interface Props {
	bidsMissed: number;
	bidValue: number;
	estimators: number;
	hitRate: number;
	hoursSaved: number;
	hoursSpent: number;
	missedBidsValue: number;
	potentialGrowth: number;
	productivityIncrease: number;
}

const BBPCalcForm: React.FC<Props> = ({
	bidsMissed,
	bidValue,
	estimators,
	hitRate,
	hoursSaved,
	hoursSpent,
	missedBidsValue,
	potentialGrowth,
	productivityIncrease,
}) => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1836)

		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			// Set the values of the form that are hidden to the user
			form.setValues({
				bc_total_estimators_on_your_team_roi_calc: estimators,
				bc_avg_hours_spent_to_complete_a_bid_roi_calc: hoursSpent,
				bc_total_missed_bids_per_year_roi_calc: bidsMissed,
				bc_average_bid_value_roi_calc: bidValue,
				bc_hit_rate_on_bids_submitted_roi_calc: hitRate,
				bc_hrs_yr_saved_roi_calc: hoursSaved,
				bc_increase_productivity_roi_calc: productivityIncrease,
				bc_missed_bids_output_roi_calc: missedBidsValue,
				bc_total_potential_growth_roi_calc: potentialGrowth,
			})

			form.onSuccess(() => {
				updateSubmitStatus(true)

				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [
		bidsMissed,
		bidValue,
		estimators,
		hitRate,
		hoursSaved,
		hoursSpent,
		missedBidsValue,
		potentialGrowth,
		productivityIncrease,
	])

	return (
		<Modal maxWidth="400px">
			{submitted ? (
				<RoiCalcSuccess ctaColor={cyan} />
			) : (
				<>
					<RoiCalcHeader />
					<MarketoForm id="mktoForm_1836" />
				</>
			)}
		</Modal>
	)
}

export default BBPCalcForm
