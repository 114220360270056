declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import { Heading, SubHead, A } from './components/shared'
import TTSuccess from './components/tt-success'
import { cyan } from 'shared/colors'
import styled from 'styled-components'
import { MarketoUrl, MarketoCode } from './constants'

const TTForm = styled(MarketoForm)`
	&.mktoForm .mktoButtonWrap.mktoSimple .mktoButton {
		background: ${cyan} !important;
		border-color: ${cyan} !important;
		:hover {
			background: ${cyan} !important;
			border-color: ${cyan} !important;
		}
		:active {
			background: ${cyan} !important;
			border-color: ${cyan} !important;
		}
		:visited {
			background: ${cyan} !important;
			border-color: ${cyan} !important;
		}
	}
`

const TTDemoRequest: React.FC = () => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1834)
		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			form.onSuccess(() => {
				updateSubmitStatus(true)
				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [])

	return (
		<Modal maxWidth="400px">
			{!submitted && (
				<>
					<div>
						<Heading>
							{location.pathname === '/bc-tradetapp/'
								? 'Learn more about the BC Pro and TradeTapp Integration'
								: 'Learn more about TradeTapp'}
						</Heading>
						<SubHead>
							Looking for help instead?{' '}
							<A href={'mailto:support@tradetapp.com'}>Contact support</A>
						</SubHead>
					</div>
					<TTForm id="mktoForm_1834" />
				</>
			)}
			{submitted && (
				<div id="request-a-demo-success-modal">
					<TTSuccess />
				</div>
			)}
		</Modal>
	)
}

export default TTDemoRequest
