// @flow
import * as React from 'react'
import { Div } from 'glamorous'

const Title = ({ wording, color }: { wording: string, color: string }) => (
	<Div
		textTransform="uppercase"
		letterSpacing="1px"
		color={color}
		fontWeight={600}
		maxWidth="100%"
	>
		{wording}
	</Div>
)

export default Title
