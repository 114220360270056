// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import defaultBlue from './img/default-blue.png'
import defaultBlue2x from './img/default-blue@2x.png'
import defaultBlue3x from './img/default-blue@3x.png'
import defaultGreen from './img/default-green.png'
import defaultGreen2x from './img/default-green@2x.png'
import defaultGreen3x from './img/default-green@3x.png'

export const BlueImage = () => (
	<Img
		width="100%"
		src={defaultBlue}
		srcSet={`${defaultBlue2x} 2x, ${defaultBlue3x} 3x`}
	/>
)

export const GreenImage = () => (
	<Img
		width="100%"
		src={defaultGreen}
		srcSet={`${defaultGreen2x} 2x, ${defaultGreen3x} 3x`}
	/>
)
