// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { laptop, tablet, mobile } from 'shared/media-queries'

const SectionSpacer = () => (
	<Div
		css={{
			height: '120px',
			[laptop]: { height: '100px' },
			[tablet]: { height: '80px' },
			[mobile]: { height: '40px' },
		}}
	/>
)

export default SectionSpacer
