// @flow
import React, { useEffect } from 'react'
import { Div } from 'glamorous'

import isBrowser from 'shared/is-browser'

// To overlay things correctly, the parent element that contains all the clickable things must be
// position relative
const WistiaVideoOverlay = ({ videoId }: { videoId: string }) => {
	useEffect(() => {
		const json = document.createElement('script')
		json.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`
		json.async = true
		document.body && document.body.appendChild(json)

		const script = document.createElement('script')
		script.src = 'https://fast.wistia.com/assets/external/E-v1.js'
		script.async = true
		document.body && document.body.appendChild(script)
	})

	return (
		<Div
			className={`wistia_embed wistia_async_${videoId} popover=true videoFoam=true popoverContent=html`}
			position="absolute"
			top="0"
			bottom="0"
			left="0"
			right="0"
			cursor="pointer"
			onClick={() => {
				if (isBrowser) {
					window._wq = window._wq || []
					window._wq.push({
						id: videoId,
						onReady: video => video.play(),
					})
				}
			}}
		/>
	)
}

export default WistiaVideoOverlay
