// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Title, H2 } from 'views/components'
import { lightBlue, grayText } from 'shared/colors'
import Spacer from 'components/spacer'
import { mobile } from 'shared/media-queries'

const SectionInfo = ({
	title,
	subtitle,
	subtitleMaxWidth,
	info,
	centered,
	titleColor = lightBlue,
}: {
	title?: string,
	subtitle?: string,
	subtitleMaxWidth: string,
	info: string,
	centered: boolean,
	titleColor?: string,
}) => (
	<Div
		css={
			centered
				? {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textAlign: 'center',
						maxWidth: '100%',
				  }
				: {}
		}
	>
		{title && <Title wording={title} color={titleColor} />}
		{title && <Spacer height="15px" />}
		{subtitle && <H2 maxWidth={subtitleMaxWidth}>{subtitle}</H2>}
		{subtitle && <Spacer height="15px" />}
		<Div
			fontSize="24px"
			color={grayText}
			css={{
				[mobile]: { fontSize: '16px' },
			}}
			maxWidth="100%"
		>
			{info}
		</Div>
	</Div>
)

export default SectionInfo
