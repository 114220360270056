declare var MktoForms2: any

import * as React from 'react'
import { useState, useEffect } from 'react'
import Modal from '../modal'
import MarketoForm from './components/marketo-form'
import RoiCalcHeader from './components/roi-calc-header'
import RoiCalcSuccess from './components/roi-calc-success'
import { lightBlue } from 'shared/colors'
import { MarketoUrl, MarketoCode } from './constants'

// prettier-ignore
interface Props {
	estimators: number;
	hoursSpentFind: number;
	hoursSpentUpdating: number;
	hrsSavedPerYear: number;
	potentialSavings: number;
	projectsAssigned: number;
	totalHours: number;
	wage: number;
}

const BCPCalcForm: React.FC<Props> = ({
	estimators,
	hoursSpentFind,
	hoursSpentUpdating,
	hrsSavedPerYear,
	potentialSavings,
	projectsAssigned,
	totalHours,
	wage,
}) => {
	const [submitted, updateSubmitStatus] = useState(false)

	useEffect(() => {
		// eslint-disable-next-line
		MktoForms2.loadForm(MarketoUrl, MarketoCode, 1837)

		// eslint-disable-next-line
		MktoForms2.whenReady(form => {
			// Set the values of the form that are hidden to the user
			form.setValues({
				bc_total_estimators_on_your_team_roi_calc: estimators,
				bc_projects_assigned_to_an_estimator_per_month_roi_calc: projectsAssigned,
				bc_hours_spent_finding_subcontractors_for_a_project_roi_calc: hoursSpentFind,
				bc_hours_spent_updating_contact_info_per_week_roi_calcu: hoursSpentUpdating,
				bc_total_hours_to_setup_a_project: totalHours,
				bc_hrs_yr_saved_roi_calc: hrsSavedPerYear,
				bc_estimated_hourly_wage_roi_calcu: wage,
				Potential_Savings_ROI_Calc: potentialSavings,
			})

			form.onSuccess(() => {
				updateSubmitStatus(true)

				// return false to prevent the submission handler from taking the lead to the follow up url.
				return false
			})
		})
	}, [
		estimators,
		hoursSpentFind,
		hoursSpentUpdating,
		hrsSavedPerYear,
		potentialSavings,
		projectsAssigned,
		totalHours,
		wage,
	])

	return (
		<Modal maxWidth="400px">
			{submitted ? (
				<RoiCalcSuccess ctaColor={lightBlue} />
			) : (
				<>
					<RoiCalcHeader />
					<MarketoForm id="mktoForm_1837" />
				</>
			)}
		</Modal>
	)
}

export default BCPCalcForm
